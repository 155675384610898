import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/oida-is-des-org-blog/oida-is-des-org-blog/src/templates/blog-post-template.tsx";
import { InlineCode } from '../../components/inline-code/inline-code';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Benutzt ihr die Tastatur, um im Internet zu surfen? Wenn ja, dann werdet ihr es zu schätzen wissen, wenn der Fokusindikator
deutlich sichtbar ist. Dieses Barrierefreiheits-Feature ist besonders wichtig für Menschen mit motorischen Beeinträchtigungen,
welche die Tastatur statt der Maus nutzen, um auf einer Webseite zu navigieren.`}</p>
    <p>{`Ich empfehle generell, die Pseudoklasse `}<InlineCode mdxType="InlineCode">{`:focus-visible`}</InlineCode>{` zu verwenden, um eigene Fokusindikatoren
zu definieren. Weitere Informationen findet ihr in meinem Artikel
`}<a parentName="p" {...{
        "href": "/de/highly-visible-keyboard-focus.de/"
      }}>{`„Gestalte deutlich sichtbaren Tastaturfokus mit :focus-visible“`}</a>{`. Aber wie berücksichtigt
man verschiedene Farbthemen und den Dark Mode?`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAQFAgP/xAAVAQEBAAAAAAAAAAAAAAAAAAACAf/aAAwDAQACEAMQAAAB1xWXKuE8U//EABkQAAIDAQAAAAAAAAAAAAAAAAECAAMRBP/aAAgBAQABBQJ2yterJXcHU2sZs5xqf//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABoQAAICAwAAAAAAAAAAAAAAAAABERIQQXH/2gAIAQEABj8Cl6IqTao08Pp//8QAGxAAAgMAAwAAAAAAAAAAAAAAABEBITFBYYH/2gAIAQEAAT8hqzYrt6M9zSKVKYOlDBM8j//aAAwDAQACAAMAAAAQG8//xAAWEQEBAQAAAAAAAAAAAAAAAAAAEQH/2gAIAQMBAT8QiY//xAAWEQEBAQAAAAAAAAAAAAAAAAAAEQH/2gAIAQIBAT8Qq6//xAAcEAEAAgMAAwAAAAAAAAAAAAABACERMUFRYXH/2gAIAQEAAT8QBGe3wzBkI2mWYWthVNfZt5lBiZDUtslmD2vhP//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Nahaufnahme von den Händen einer Person, die auf einer Laptop-Tastatur schreibt",
          "title": "Nahaufnahme von den Händen einer Person, die auf einer Laptop-Tastatur schreibt",
          "src": "/static/58a5de4c0dd0ba8473945cd4519d5b1d/e5166/pexels-cottonbro-keyboard.jpg",
          "srcSet": ["/static/58a5de4c0dd0ba8473945cd4519d5b1d/f93b5/pexels-cottonbro-keyboard.jpg 300w", "/static/58a5de4c0dd0ba8473945cd4519d5b1d/b4294/pexels-cottonbro-keyboard.jpg 600w", "/static/58a5de4c0dd0ba8473945cd4519d5b1d/e5166/pexels-cottonbro-keyboard.jpg 1200w", "/static/58a5de4c0dd0ba8473945cd4519d5b1d/b17f8/pexels-cottonbro-keyboard.jpg 1600w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<em parentName="p">{`Foto: © cottonbro / pexels.com`}</em></p>
    <p>{`Die elegante Lösung lautet: Definiert einen zweifarbigen Fokusindikator, der einen ausreichenden Kontrast zu jedem einfarbigen
Hintergrund aufweist. Die `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/WCAG22/Techniques/css/C40"
      }}>{`Technik C40 des W3C`}</a>{` empfiehlt diese Lösung als
eine Möglichkeit, die WCAG-Kriterien `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/WCAG22/Understanding/focus-visible"
      }}>{`2.4.7: Fokus sichtbar`}</a>{`
und `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/WCAG22/Understanding/focus-appearance.html"
      }}>{`2.4.13: Fokus-Erscheinungsbild`}</a>{` zu erfüllen.`}</p>
    <h2>{`Demo: Zweifarbiger Fokusindikator`}</h2>
    <p>{`Hier ist meine `}<a parentName="p" {...{
        "href": "https://codepen.io/alexlehner86/pen/wvZJbBd"
      }}>{`Demo`}</a>{` einer barrierefreien zweifarbigen Fokusanzeige. Mit der
Checkbox „Dark Mode“ könnt ihr zwischen hellem und dunklem Modus umschalten. Fokussiert die Schaltfläche und den Link mit der
Tab-Taste und beobachtet den Fokusindikator:`}</p>
    <iframe title="Zweifarbiger Fokusindikator" src="https://codepen.io/alexlehner86/embed/wvZJbBd?default-tab=result" loading="lazy">
    Öffne das Code-Beispiel <a href="https://codepen.io/alexlehner86/pen/wvZJbBd" lang="en">
    Two-color focus indicator</a> von Alexander Lehner (<a href="https://codepen.io/alexlehner86">@alexlehner86</a>)
    auf <a href="https://codepen.io">CodePen</a>.
    </iframe>
    <p>{`Wie funktioniert das Ganze?`}</p>
    <h2>{`Die (nicht allzu) geheimen CSS-Zutaten`}</h2>
    <p>{`Um den zweifarbigen Fokusindikator zu erstellen, kombiniert einfach die CSS-Eigenschaften `}<InlineCode mdxType="InlineCode">{`outline`}</InlineCode>{`
und `}<InlineCode mdxType="InlineCode">{`box-shadow`}</InlineCode>{`, um eine helle und dunkle Linie um das fokussierte Element zu zeichnen.`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`:is(a[href], button):focus-visible {
    /* inner indicator */
    outline: 3px #fff solid;
    outline-offset: 0;
    /* outer indicator */
    box-shadow: 0 0 0 5px #000;
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Eine der Linien bietet immer einen ausreichenden Kontrast zur aktuellen Hintergrundfarbe. In meiner Demo habe ich eine
einfache Kombination aus reinem Weiß und Schwarz verwendet. Ihr könnt aber auch andere Farben verwenden, wie
die W3C-Technik erklärt:`}</p>
    <blockquote lang="en">
    As long as the two indicator colors have a <strong>contrast ratio of at least 9:1 with each other</strong>, at least
    one of the two colors is guaranteed to meet 3:1 contrast with any solid background color.
    </blockquote>
    <p>{`Der zweifarbige Fokusindikator funktioniert sogar im `}<span lang="en">{`Forced Colors Modus`}</span>{`.
Wenn ihr mit diesem Feature nicht vertraut seid, lest meinen Artikel
`}<a parentName="p" {...{
        "href": "/de/forced-colors-mode.de/"
      }}>{`„CSS-Tricks für bessere Sichtbarkeit im Forced Colors Modus“`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      